<template>
  <div class="mt-0-px pb-0" v-if="!isExtern">
    <div id="portfolio" v-if="angemeldeterMitarbeiter">
      <navigation-arvea :angemeldeterMitarbeiter="angemeldeterMitarbeiter" />
      <!-- BANNER 
      <div class="row banner">
        <div class="col-3"></div>
        <div class="col-6">
          <h1>ARVEA Universe Portfolio</h1>
        </div>
        <div class="col-3">
          <img src="https://arvea-dev.batix.ch/pic/183F4884E9A_241x241.png" />
        </div>
      </div>-->
      <!-- TITLE -->
      <div class="row title">
        <div class="col-xl-12 d-flex align-items-center">
          <h1 class="px-3 mr-auto">Portfolio</h1>
          <div class="row ml-auto">
            <div
              class="ml-auto uploadUniverse d-flex align-items-center"
              @click="
                showDropzone = !showDropzone;
                showDataDropzone = false;
              "
              :class="showDropzone ? 'active' : ''"
            >
              <svg
                id="vuesax_bold_document-upload"
                data-name="vuesax/bold/document-upload"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g id="document-upload">
                  <path
                    id="Vector"
                    d="M18,8.19H15.11a4.306,4.306,0,0,1-4.3-4.3V1a1,1,0,0,0-1-1H5.57A5.277,5.277,0,0,0,0,5.57v8.86A5.277,5.277,0,0,0,5.57,20h7.86A5.277,5.277,0,0,0,19,14.43V9.19A1,1,0,0,0,18,8.19ZM9.03,11.53a.748.748,0,0,1-1.06,0l-.72-.72V15a.75.75,0,0,1-1.5,0V10.81l-.72.72a.75.75,0,0,1-1.06-1.06l2-2a1.071,1.071,0,0,1,.22-.15c.02-.01.05-.02.07-.03a1.062,1.062,0,0,1,.19-.04h.08a.687.687,0,0,1,.24.05h.02a.662.662,0,0,1,.22.15c.01.01.02.01.02.02l2,2A.754.754,0,0,1,9.03,11.53Z"
                    transform="translate(2.5 2)"
                    fill="#213056"
                  />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M2.75,6.795c.95.01,2.27.01,3.4.01a.631.631,0,0,0,.47-1.07C5.18,4.285,2.6,1.675,1.12.195A.654.654,0,0,0,0,.635v3.49A2.726,2.726,0,0,0,2.75,6.795Z"
                    transform="translate(14.68 2.015)"
                    fill="#213056"
                  />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H24V24H0Z"
                    transform="translate(24 24) rotate(180)"
                    fill="none"
                    opacity="0"
                  />
                </g>
              </svg>
              <div class="d-flex flex-column ml-3">
                <span class="uploadUniverseTitle">Upload Universe</span>
              </div>
            </div>
            <div
              class="ml-5 uploadUniverse d-flex align-items-center"
              @click="
                showDataDropzone = !showDataDropzone;
                showDropzone = false;
              "
              :class="showDataDropzone ? 'active' : ''"
            >
              <svg
                id="vuesax_bold_document-upload"
                data-name="vuesax/bold/document-upload"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g id="document-upload">
                  <path
                    id="Vector"
                    d="M18,8.19H15.11a4.306,4.306,0,0,1-4.3-4.3V1a1,1,0,0,0-1-1H5.57A5.277,5.277,0,0,0,0,5.57v8.86A5.277,5.277,0,0,0,5.57,20h7.86A5.277,5.277,0,0,0,19,14.43V9.19A1,1,0,0,0,18,8.19ZM9.03,11.53a.748.748,0,0,1-1.06,0l-.72-.72V15a.75.75,0,0,1-1.5,0V10.81l-.72.72a.75.75,0,0,1-1.06-1.06l2-2a1.071,1.071,0,0,1,.22-.15c.02-.01.05-.02.07-.03a1.062,1.062,0,0,1,.19-.04h.08a.687.687,0,0,1,.24.05h.02a.662.662,0,0,1,.22.15c.01.01.02.01.02.02l2,2A.754.754,0,0,1,9.03,11.53Z"
                    transform="translate(2.5 2)"
                    fill="#213056"
                  />
                  <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M2.75,6.795c.95.01,2.27.01,3.4.01a.631.631,0,0,0,.47-1.07C5.18,4.285,2.6,1.675,1.12.195A.654.654,0,0,0,0,.635v3.49A2.726,2.726,0,0,0,2.75,6.795Z"
                    transform="translate(14.68 2.015)"
                    fill="#213056"
                  />
                  <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H24V24H0Z"
                    transform="translate(24 24) rotate(180)"
                    fill="none"
                    opacity="0"
                  />
                </g>
              </svg>
              <div class="d-flex flex-column ml-3">
                <span class="uploadUniverseTitle">Upload Data</span>
                <span v-if="uploadDates[0]" class="uploadUniverseSubtitle"
                  >last updated {{ uploadDates[0].date }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- UPLOAD UNIVERSE -->
      <div
        v-if="showDropzone"
        class="row uploadRow d-flex justify-content-center align-items-center flex-column mt-5"
      >
        <div class="col-2 mt-5 text-center">
          <div class="dropzoneTitle">Upload Universe</div>
          <div class="dropzoneContainer">
            <vue-2-dropzone
              id="dropzone"
              ref="portfolioUpload"
              class="mt-3"
              :options="dropzoneOptions"
              @vdropzone-removedfile="fileAdded"
              @vdropzone-success="fileUploaded"
              @vdropzone-error="uploadError"
              @vdropzone-file-added="fileSelected"
              @vdropzone-removed-file="fileRemoved"
            >
            </vue-2-dropzone>
            <div
              class="uploadButton"
              @click="portfolioBereinigen"
              :style="
                flagFileSelected
                  ? 'background-color: #1B2C55; cursor: pointer;'
                  : 'background-color: #8F98AE; cursor: auto'
              "
            >
              <p>Upload</p>
              <svg
                id="upload_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="22.216"
                height="22.8"
                viewBox="0 0 22.216 22.8"
              >
                <path
                  id="Path_64"
                  data-name="Path 64"
                  d="M170.351,20.849l-4.677-4.677a.585.585,0,0,0-.827,0l-4.677,4.677a.585.585,0,1,0,.827.827L164.676,18V30.031a.585.585,0,0,0,1.169,0V18l3.679,3.679a.585.585,0,1,0,.827-.827Z"
                  transform="translate(-154.153 -16)"
                  fill="#fff"
                />
                <path
                  id="Path_65"
                  data-name="Path 65"
                  d="M11.108,300.86a16.2,16.2,0,0,1-7.725-1.786C1.2,297.862,0,296.212,0,294.429c0-3.088,3.657-5.791,8.7-6.426a.585.585,0,0,1,.159,1.158,9.419,9.419,0,0,0-3.941,1.44,2.762,2.762,0,0,0-1.406,2.074c0,.848.756,1.712,2.074,2.371a12.663,12.663,0,0,0,5.526,1.137,12.663,12.663,0,0,0,5.526-1.137c1.318-.659,2.074-1.523,2.074-2.371A2.762,2.762,0,0,0,17.3,290.6a9.419,9.419,0,0,0-3.941-1.44A.585.585,0,0,1,13.52,288c5.039.635,8.7,3.338,8.7,6.426,0,1.783-1.2,3.433-3.383,4.645A16.2,16.2,0,0,1,11.108,300.86ZM2.486,291.79a3.714,3.714,0,0,0-1.317,2.639c0,1.34.988,2.626,2.782,3.623a15.018,15.018,0,0,0,7.157,1.639,15.018,15.018,0,0,0,7.157-1.639c1.794-1,2.782-2.283,2.782-3.623a3.715,3.715,0,0,0-1.317-2.639,2.775,2.775,0,0,1,.148.885c0,1.326-.966,2.539-2.72,3.417a13.841,13.841,0,0,1-6.049,1.26,13.841,13.841,0,0,1-6.049-1.26c-1.754-.877-2.72-2.091-2.72-3.417A2.774,2.774,0,0,1,2.486,291.79Z"
                  transform="translate(0 -278.059)"
                  fill="#fff"
                />
              </svg>
            </div>
            <div class="dropzoneIcon">
              <svg
                id="drag_drop_icon"
                data-name="drag&amp;drop_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="48.617"
                height="61.582"
                viewBox="0 0 48.617 61.582"
              >
                <path
                  id="Path_3945"
                  data-name="Path 3945"
                  d="M214.213,66.507a1.621,1.621,0,0,0-2.292,0l-6.957,6.957V33.621a1.621,1.621,0,0,0-3.241,0V73.464l-6.957-6.957a1.621,1.621,0,0,0-2.292,2.292l9.723,9.723a1.62,1.62,0,0,0,2.292,0l9.723-9.723A1.62,1.62,0,0,0,214.213,66.507Z"
                  transform="translate(-179.035 -32)"
                  fill="#eaedf6"
                />
                <path
                  id="Path_3946"
                  data-name="Path 3946"
                  d="M107.755,266.135H68.862A4.867,4.867,0,0,1,64,261.273V228.862A4.867,4.867,0,0,1,68.862,224H81.826a1.621,1.621,0,1,1,0,3.241H68.862a1.622,1.622,0,0,0-1.621,1.621v32.411a1.623,1.623,0,0,0,1.621,1.621h38.894a1.623,1.623,0,0,0,1.621-1.621V228.862a1.622,1.622,0,0,0-1.621-1.621H94.791a1.621,1.621,0,0,1,0-3.241h12.965a4.867,4.867,0,0,1,4.862,4.862v32.411A4.867,4.867,0,0,1,107.755,266.135Z"
                  transform="translate(-64 -204.553)"
                  fill="#eaedf6"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="uploadBorder"></div>
      </div>

      <!-- UPLOAD DATA-->
      <div
        v-if="showDataDropzone"
        class="row uploadRow d-flex justify-content-center align-items-center flex-column mt-5"
      >
        <div class="col-2 mt-5 text-center">
          <div class="dropzoneTitle">Upload Data</div>
          <div class="dropzoneContainer">
            <vue-2-dropzone
              id="dataDropzone"
              ref="dataUpload"
              class="mt-3"
              :options="dataDropzoneOptions"
              @vdropzone-removedfile="fileAdded"
              @vdropzone-success="dataUploaded"
              @vdropzone-error="uploadError"
              @vdropzone-file-added="fileSelected"
              @vdropzone-removed-file="fileRemoved"
            >
            </vue-2-dropzone>
            <div
              class="uploadButton"
              @click="uploadData"
              :style="
                flagFileSelected
                  ? 'background-color: #1B2C55; cursor: pointer;'
                  : 'background-color: #8F98AE; cursor: auto'
              "
            >
              <p>Upload</p>
              <svg
                id="upload_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="22.216"
                height="22.8"
                viewBox="0 0 22.216 22.8"
              >
                <path
                  id="Path_64"
                  data-name="Path 64"
                  d="M170.351,20.849l-4.677-4.677a.585.585,0,0,0-.827,0l-4.677,4.677a.585.585,0,1,0,.827.827L164.676,18V30.031a.585.585,0,0,0,1.169,0V18l3.679,3.679a.585.585,0,1,0,.827-.827Z"
                  transform="translate(-154.153 -16)"
                  fill="#fff"
                />
                <path
                  id="Path_65"
                  data-name="Path 65"
                  d="M11.108,300.86a16.2,16.2,0,0,1-7.725-1.786C1.2,297.862,0,296.212,0,294.429c0-3.088,3.657-5.791,8.7-6.426a.585.585,0,0,1,.159,1.158,9.419,9.419,0,0,0-3.941,1.44,2.762,2.762,0,0,0-1.406,2.074c0,.848.756,1.712,2.074,2.371a12.663,12.663,0,0,0,5.526,1.137,12.663,12.663,0,0,0,5.526-1.137c1.318-.659,2.074-1.523,2.074-2.371A2.762,2.762,0,0,0,17.3,290.6a9.419,9.419,0,0,0-3.941-1.44A.585.585,0,0,1,13.52,288c5.039.635,8.7,3.338,8.7,6.426,0,1.783-1.2,3.433-3.383,4.645A16.2,16.2,0,0,1,11.108,300.86ZM2.486,291.79a3.714,3.714,0,0,0-1.317,2.639c0,1.34.988,2.626,2.782,3.623a15.018,15.018,0,0,0,7.157,1.639,15.018,15.018,0,0,0,7.157-1.639c1.794-1,2.782-2.283,2.782-3.623a3.715,3.715,0,0,0-1.317-2.639,2.775,2.775,0,0,1,.148.885c0,1.326-.966,2.539-2.72,3.417a13.841,13.841,0,0,1-6.049,1.26,13.841,13.841,0,0,1-6.049-1.26c-1.754-.877-2.72-2.091-2.72-3.417A2.774,2.774,0,0,1,2.486,291.79Z"
                  transform="translate(0 -278.059)"
                  fill="#fff"
                />
              </svg>
            </div>
            <div class="dropzoneIcon">
              <svg
                id="drag_drop_icon"
                data-name="drag&amp;drop_icon"
                xmlns="http://www.w3.org/2000/svg"
                width="48.617"
                height="61.582"
                viewBox="0 0 48.617 61.582"
              >
                <path
                  id="Path_3945"
                  data-name="Path 3945"
                  d="M214.213,66.507a1.621,1.621,0,0,0-2.292,0l-6.957,6.957V33.621a1.621,1.621,0,0,0-3.241,0V73.464l-6.957-6.957a1.621,1.621,0,0,0-2.292,2.292l9.723,9.723a1.62,1.62,0,0,0,2.292,0l9.723-9.723A1.62,1.62,0,0,0,214.213,66.507Z"
                  transform="translate(-179.035 -32)"
                  fill="#eaedf6"
                />
                <path
                  id="Path_3946"
                  data-name="Path 3946"
                  d="M107.755,266.135H68.862A4.867,4.867,0,0,1,64,261.273V228.862A4.867,4.867,0,0,1,68.862,224H81.826a1.621,1.621,0,1,1,0,3.241H68.862a1.622,1.622,0,0,0-1.621,1.621v32.411a1.623,1.623,0,0,0,1.621,1.621h38.894a1.623,1.623,0,0,0,1.621-1.621V228.862a1.622,1.622,0,0,0-1.621-1.621H94.791a1.621,1.621,0,0,1,0-3.241h12.965a4.867,4.867,0,0,1,4.862,4.862v32.411A4.867,4.867,0,0,1,107.755,266.135Z"
                  transform="translate(-64 -204.553)"
                  fill="#eaedf6"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="uploadBorder"></div>
      </div>

      <!-- CREATE PORTFOLIOS -->

      <div class="row upload d-flex justify-content-center align-items-center">
        <div class="col-4 d-flex flex-column">
          <div class="form-group portfolioDropdown">
            <v-select
              v-model="selectedDate"
              label="date"
              placeholder="Date"
              :options="uploadDates"
              name="dates"
            >
              <span slot="no-options"> Keine Optionen </span>
            </v-select>
            <label for="dates">Dates</label>
          </div>
          <div class="form-group portfolioDropdown">
            <v-select
              v-model="selectedUniverse"
              label="name"
              placeholder="Universe"
              :options="universes"
              name="universes"
            >
              <span slot="no-options"> Keine Optionen </span>
            </v-select>
            <label for="universes">Universes</label>
          </div>
          <div class="mt-3 d-flex w-100">
            <button
              class="btn btn-arvea-dark ml-auto"
              @click="createPortfolios"
            >
              Create portfolios
            </button>
          </div>
        </div>
      </div>

      <!-- GRAPH -->
      <div
        v-if="portfoliosLoaded"
        class="row portfolioGraph m-5 p-5 d-flex justify-content-center align-items-center flex-column"
      >
        <div class="switchPortfolioButtons">
          <div class="d-flex flex-column portfolioButtonsTitle mr-auto">
            <h2>Investment options portfolio {{ activePortfolioIndex + 1 }}</h2>
            <span>Results are defined by your filtering options</span>
          </div>
          <button
            class="btn btn-arvea-dark"
            @click="copyToClipboard('portfolio-container')"
          >
            Copy
          </button>
          <div
            class="d-flex"
            v-for="(portfoliogruppen, index) in portfolios"
            :key="'portfoliogruppe-' + index"
          >
            <div
              class="switchPortfolioButton"
              @click="activePortfolioIndex = index"
              :class="activePortfolioIndex == index ? 'active' : ''"
            >
              <i
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="vuesax_bold_chart-square"
                    data-name="vuesax/bold/chart-square"
                    transform="translate(-748 -252)"
                  >
                    <g id="chart-square">
                      <path
                        id="Vector"
                        d="M0,0H24V24H0Z"
                        transform="translate(748 252)"
                        fill="none"
                        opacity="0"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M14.19,0H5.81C2.17,0,0,2.17,0,5.81v8.37C0,17.83,2.17,20,5.81,20h8.37c3.64,0,5.81-2.17,5.81-5.81V5.81C20,2.17,17.83,0,14.19,0ZM7.11,14.9a.5.5,0,0,1-.5.5H3.82a.5.5,0,0,1-.5-.5V10.28A1.139,1.139,0,0,1,4.46,9.14H6.61a.5.5,0,0,1,.5.5Zm4.78,0a.5.5,0,0,1-.5.5H8.6a.5.5,0,0,1-.5-.5V5.74A1.139,1.139,0,0,1,9.24,4.6h1.52A1.139,1.139,0,0,1,11.9,5.74V14.9Zm4.79,0a.5.5,0,0,1-.5.5H13.39a.5.5,0,0,1-.5-.5V11.35a.5.5,0,0,1,.5-.5h2.15a1.139,1.139,0,0,1,1.14,1.14Z"
                        transform="translate(750 254)"
                        fill="#213056"
                      />
                    </g>
                  </g>
                </svg>
              </i>
              <span v-if="index + 1 == 1">M</span>
              <span v-if="index + 1 == 2">A</span>
            </div>
          </div>
        </div>

        <div
          id="portfolio-container"
          class="col-xl-12 d-flex align-items-center flex-column"
        >
          <div
            class="portfolioTitle w-100"
            v-for="(portfolio, index) in portfolios[activePortfolioIndex]
              .portfolios"
            :key="index"
          >
            <div
              class="portfolio col-12 d-flex align-items-center flex-row w-100"
              :style="{ background: portfolioColors[index] }"
              v-b-toggle="'collapse-' + index"
              @click.prevent
            >
              <div class="col-12 d-flex">
                <div class="portfolioLabel d-flex align-items-center">
                  Portfolio {{ portfolio.number }}
                </div>

                <div
                  v-if="portfolio.size"
                  class="portfolioInfos d-flex flex-column"
                >
                  <span class="portfolioInfos_label">Size</span>
                  <span class="portfolioInfos_value">{{ portfolio.size }}</span>
                </div>

                <div
                  v-if="portfolio['sum w %']"
                  class="portfolioInfos d-flex flex-column"
                >
                  <span class="portfolioInfos_label">Sum w %</span>
                  <span class="portfolioInfos_value">{{
                    portfolio["sum w %"]
                  }}</span>
                </div>

                <div
                  v-if="portfolio['sum w-peg']"
                  class="portfolioInfos d-flex flex-column"
                >
                  <span class="portfolioInfos_label">Sum w-peg</span>
                  <span class="portfolioInfos_value">{{
                    portfolio["sum w-peg"]
                  }}</span>
                </div>

                <div
                  v-if="portfolio['sum w-s %']"
                  class="portfolioInfos d-flex flex-column"
                >
                  <span class="portfolioInfos_label">Sum w-s %</span>
                  <span class="portfolioInfos_value">{{
                    portfolio["sum w-s %"]
                  }}</span>
                </div>

                <div
                  v-if="portfolio['sum w-s2']"
                  class="portfolioInfos d-flex flex-column"
                >
                  <span class="portfolioInfos_label">Sum w-s2</span>
                  <span class="portfolioInfos_value">{{
                    portfolio["sum w-s2"]
                  }}</span>
                </div>

                <div
                  v-if="portfolio['sum weight %']"
                  class="portfolioInfos d-flex flex-column"
                >
                  <span class="portfolioInfos_label">Sum weight %</span>
                  <span class="portfolioInfos_value">{{
                    portfolio["sum weight %"]
                  }}</span>
                </div>

                <div class="when-open ml-auto">
                  <i class="icon-Chevron-down"></i>
                </div>
                <div class="when-closed ml-auto">
                  <i class="icon-Chevron-up"></i>
                </div>
              </div>
            </div>
            <b-collapse v-bind:id="'collapse-' + index">
              <div class="col-12 d-flex p-0">
                <b-table
                  class="portfolioTable m-0"
                  ref="selectableTable"
                  tbody-tr-class="item"
                  :items="
                    portfolios[activePortfolioIndex].portfolios[index]
                      .attributes
                  "
                  :fields="
                    portfolios[activePortfolioIndex].portfolios[index].fields
                  "
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :sort-compare="sortDates"
                  :busy="isBusy"
                  striped
                  hover
                  sort-icon-left
                  fixed
                  selectable
                  borderless
                  select-mode="multi"
                  :style="{ background: portfolioColors[index] }"
                >
                </b-table>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import page from "@/mixins/Page";
import store from "@/store";
import vue2Dropzone from "vue2-dropzone";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { dropzoneOptionsPortfolio } from "@/config/Dropzone";
import { dataDropzoneOptionsPortfolio } from "@/config/Dropzone";
import Api from "@/Api";
import NavigationArvea from "@/components/Navigation/NavigationArvea";

export default {
  name: "Portfolio",
  components: {
    vue2Dropzone,
    NavigationArvea,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Arvea Universe Portfolio";
      },
    };
  },
  data() {
    return {
      page: 0,
      isBusy: false,
      loading: false,
      sortBy: "",
      sortDesc: true,
      sortDirection: "desc",
      showDropzone: false,
      showDataDropzone: false,
      flagFileSelected: false,
      flagFileUploaded: false,
      flagFileAdded: false,
      flagFileCalculated: false,
      dropzoneOptions: dropzoneOptionsPortfolio(Api.defaults.baseURL),
      dataDropzoneOptions: dataDropzoneOptionsPortfolio(Api.defaults.baseURL),
      selectedDate: null,
      selectedUniverse: null,
      uploadDates: [],
      universes: [],
      activePortfolioIndex: 0,
      portfoliosLoaded: false,
      portfolios: [
        {
          number: "1",
          attributes: [
            {
              name: "",
              s: 0,
              s2: 0,
              peg: 0,
              s_quotient: 0,
              s2_quotient: 0,
              peg_quotient: 0,
              s_by_s2: 0,
              w: 0,
              w_s: 0,
              w_s2: 0,
              s_by_s2_by_peg: 0,
              w_peg: 0,
              s2_by_peg: 0,
              s_by_peg: 0,
              inverse: 0,
              weight: 0,
              switched_peg: 0,
              s2_by_switched_peg: 0,
            },
          ],
        },
      ],
      portfolioColors: [
        //flat
        "#E4D1CA", //salmon
        "#C3CBD8", //mauve
        "#ABBEC4", //slate
        "#A6CCD7", //moody blue
        "#AAD2D4", //duck egg
        "#ACC8B1", //sage
        "#CDDBB7", //olive
        "#EEE9D6", //camel

        //gradients
        "linear-gradient(0deg, rgba(228, 209, 202, 0.85) 20%, rgb(228, 209, 202) 100%)", //salmon
        "linear-gradient(0deg, rgba(195, 203, 216, 0.85) 20%, rgb(195, 203, 216) 100%)", //mauve
        "linear-gradient(0deg, rgba(171, 190, 196, 0.85) 20%, rgb(171, 190, 196) 100%)", //slate
        "linear-gradient(0deg, rgba(166, 204, 215, 0.85) 20%, rgb(166, 204, 215) 100%)", //moody blue
        "linear-gradient(0deg, rgba(170, 210, 212, 0.85) 20%, rgb(170, 210, 212) 100%)", //duck egg
        "linear-gradient(0deg, rgba(172, 200, 177, 0.85) 20%, rgb(172, 200, 177) 100%)", //sage
        "linear-gradient(0deg, rgba(205, 219, 183, 0.85) 20%, rgb(205, 219, 183) 100%)", //olive
        "linear-gradient(0deg, rgba(238, 233, 214, 0.85) 20%, rgb(238, 233, 214) 100%)", //camel
      ],
    };
  },
  watch: {},
  computed: {
    permissions() {
      return this.$store.state.permissions;
    },
  },
  created() {
    Api.get("/portfolio/select-data/").then((response) => {
      this.uploadDates = response.data.dates;
      this.universes = response.data.universes;
      this.selectedDate = this.uploadDates[0];
    });
  },
  mounted() {},
  methods: {
    copyToClipboard(containerid) {
      let containerNode = document.getElementById(containerid);
      var textArea = document.createElement("textarea");
      textArea.value = containerNode.outerHTML;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      document.body.removeChild(textArea);
      alert("Portfolio Data copied");
    },
    createPortfolios() {
      this.portfoliosLoaded = false;
      console.log("create PF");
      Api.post("/portfolio/create/", null, {
        params: {
          date: this.selectedDate?.id,
          universe: this.selectedUniverse?.id,
        },
      }).then((response) => {
        this.portfolios = response.data;
        this.portfoliosLoaded = true;
      });
    },
    startseite() {
      let startseite = this.$router.resolve({ name: "StartseiteArvea" });
      window.location.href = startseite.href;
    },
    routeOptions() {
      window.location.href = this.$router.resolve({ name: "Options" }).href;
    },
    routeCalculator() {
      let calculator = this.$router.resolve({ name: "Calculator" });
      window.location.href = calculator.href;
    },
    routeETF() {
      window.location.href = this.$router.resolve({ name: "ETF" }).href;
    },
    routePortfolio() {
      window.location.href = this.$router.resolve({ name: "Portfolio" }).href;
    },
    formatNum(num) {
      if (num != null) {
        if (typeof num == "number") {
          var str = num.toLocaleString("en-US");
          str = str.replace(/\./, ".");
          str = str.replace(/,/g, "'");
          return str;
        } else return num;
      } else {
        return "-";
      }
    },
    expo(x, f) {
      return Number.parseFloat(x).toExponential(f);
    },
    fileAdded() {
      this.flagFileAdded = true;
    },
    fileSelected() {
      this.flagFileSelected = true;
    },
    fileRemoved() {
      this.flagFileSelected = false;
      this.calculateColor = "#EAEDF6";
      this.resultColor = "#EAEDF6";
    },
    async fileUploaded(file) {
      //this.$store.state.selectedEquities = [];
      let json = await JSON.parse(file.xhr.response);
      this.portfolios = json;
      this.flagFileUploaded = true;
      this.calculateColor = "#213056";
      this.buildFields();
    },
    async dataUploaded(/*file*/) {
      //Enis pls fix :)
    },
    buildFields() {
      this.portfolios.forEach((portfoliogruppe) => {
        portfoliogruppe.portfolios.forEach((portfolio) => {
          portfolio.fields = [];
          portfolio.fields.push({
            key: "name",
            sortable: true,
            label: "Equity",
            class: "text-left",
          });

          if (portfolio.attributes[0]) {
            for (const [key, value] of Object.entries(
              portfolio.attributes[0]
            )) {
              if (value != null && key != "name") {
                portfolio.fields.push({
                  key: `${key}`,
                  sortable: true,
                  label: `${key}`,
                  class: "text-left",
                  formatter: (value) => {
                    return parseFloat(value?.toFixed(8));
                    //return this.formatNum(value);
                    //return Number.parseFloat(value).toExponential(10)
                  },
                });
              }
            }
          }
        });
      });
      this.portfoliosLoaded = true;
    },
    uploadError(error) {
      this.$notify(apiErrorToAlert(error));
      this.$notify({
        type: "error",
        title: "Aktion fehlgeschlagen",
        text: "Fehler beim Hochladen der Datei. ",
        duration: 7000,
      });
      this.flagFileUploaded = false;
    },
    portfolioBereinigen() {
      this.portfoliosLoaded = false;
      this.$refs.portfolioUpload.processQueue();
    },
    uploadData() {
      this.$refs.dataUpload.processQueue();
    },
    sortDates(datumA, datumB, key) {
      if (key != "date") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
body {
  background: white;
  color: #213056;
  overflow-x: hidden;
}
#portfolio .title {
  position: relative;
  z-index: 10;
  margin-bottom: 10px;
  padding: 45px 7.5%;
  background: #a6ccd7;
  /*background: linear-gradient(
    90deg,
    rgba(166, 204, 215, 0.8) 10%,
    #a6ccd7 100%
  );*/
}
#portfolio .title .uploadUniverse {
  cursor: pointer;
}
#portfolio .title .uploadUniverse:hover,
#portfolio .title .uploadUniverse.active {
  color: #576484;
}
#portfolio .title .uploadUniverse:hover path,
#portfolio .title .uploadUniverse.active path {
  fill: #576484;
}
#portfolio .title h1 {
  font-size: 36px;
}
#portfolio .title svg {
  width: 24px;
}
#portfolio .title .uploadUniverseTitle {
  font-size: 17px;
  font-weight: 500;
}
#portfolio .title .uploadUniverseSubtitle {
  font-size: 14px;
}
#portfolio .banner {
  background-color: #c6ccdd;
  height: 300px;
  width: 100vw;
}
#portfolio .banner h1 {
  padding-top: 65px;
  font-weight: 700;
  color: #e6edff;
}
#portfolio .banner p {
  padding-top: 10px;
  font-size: 16px;
  padding-right: 30%;
}
#portfolio .banner > div:last-child {
  display: flex;
  align-items: center;
}
#portfolio .portfolioDropdown {
  position: relative;
}
#portfolio .portfolioDropdown label {
  left: 0;
}
#portfolio th {
  cursor: pointer;
}
#portfolio .etfTable {
  cursor: pointer;
}
#portfolio .detailTable {
  cursor: default;
}
#portfolio .portfolio {
  font-size: 22px;
  cursor: pointer;
  padding: 35px 50px;
  border-radius: 10px 10px 10px 10px;
  margin-top: 13px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
#portfolio .portfolioLabel {
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 25px;
  margin-right: 10%;
}
#portfolio .portfolio.not-collapsed {
  //border-radius: 10px 10px 0 0;
  animation: border-animation-backward 0.05s ease 1;
  animation-direction: normal;
  animation-fill-mode: both;
}
#portfolio .portfolio.collapsed {
  animation: border-animation-forward 0.8s ease 1;
  animation-direction: normal;
  animation-fill-mode: both;
}
#portfolio .when-open i,
#portfolio .when-closed i {
  height: fit-content;
}
@keyframes border-animation-backward {
  from {
    border-radius: 10px 10px 10px 10px;
  }
  to {
    border-radius: 10px 10px 0 0;
  }
}
@keyframes border-animation-forward {
  from {
    border-radius: 10px 10px 0 0;
  }
  to {
    border-radius: 10px 10px 10px 10px;
  }
}
#portfolio .portfolio:hover {
  filter: brightness(110%);
}
#portfolio .upload {
  border: none;
  border-radius: 0px;
  padding: 50px 10px;
  margin-top: 65px;
}
#portfolio .uploadBorder {
  width: 40%;
  border-bottom: 1px solid #a6ccd7;
  margin-top: 95px;
}
#portfolio .portfolioButtonsTitle {
  padding-left: 15px;
}
#portfolio .switchPortfolioButtons {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-bottom: 15px;
}
#portfolio .switchPortfolioButton {
  cursor: pointer;
  margin: 0 14px 0 15px;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 10px 21px;
}
#portfolio .switchPortfolioButton i {
  display: flex;
  margin-right: 13px;
}
#portfolio .switchPortfolioButton {
  border: 1px solid lightgray;
  border-radius: 5px;
}
#portfolio .switchPortfolioButton.active {
  background: #24355f;
  color: white;
  border: none;
}
#portfolio .switchPortfolioButton.active path {
  fill: white;
}
#portfolio .dropzoneTitle {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 35px;
}
#portfolio .vue-dropzone {
  background-color: transparent !important;
  border: 2px #8e97af dashed !important;
  height: 220px;
  position: relative;
  z-index: 2;
  padding: 0;
}
#portfolio .vue-dropzone .dz-preview {
  position: relative;
  width: 99%;
  height: 76.5%;
  left: -4%;
  bottom: 4%;
  font-family: Montserrat;
  margin-right: 0;
  margin-left: 4.5%;
}
#portfolio .vue-dropzone .dz-image {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
#portfolio .vue-dropzone .dz-progress {
  width: 80%;
  position: absolute;
  left: 10%;
  margin: auto;
  margin-top: 60px !important;
  background: #c5cbdc;
}
#portfolio .vue-dropzone .dz-upload {
  background: #8e97af;
}
#portfolio .vue-dropzone .dz-details {
  border-radius: 17px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: linear-gradient(0deg, rgb(2, 0, 36) 0%, #223259 100%);
}
#portfolio .vue-dropzone .dz-size {
  position: relative;
  bottom: -19%;
}
#portfolio .vue-dropzone .dz-filename {
  position: relative;
  bottom: 45%;
  width: 100%;
}
#portfolio .vue-dropzone .dz-filename > span {
  line-height: 100%;
}
#portfolio .vue-dropzone .dz-remove {
  position: absolute;
  bottom: 80%;
  top: 3%;
  background-color: transparent;
  font-size: 18px;
  text-decoration: none;
}
#portfolio .vue-dropzone .dz-error-mark {
  position: absolute;
  width: 10%;
  top: 50%;
  left: 40%;
}
#portfolio .vue-dropzone .dz-success-mark {
  position: absolute;
  width: 10%;
  top: 50%;
  left: 40%;
}
#portfolio .vue-dropzone .dz-error-message {
  display: none !important;
}
#portfolio .uploadButton {
  background-color: #8e97af;
  color: white;
  position: relative;
  bottom: 50px;
  height: 50px;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  z-index: 3;
}
#portfolio .uploadButton p {
  margin: 0;
  margin-right: auto;
  padding-left: 17px;
  font-size: 1vw;
}
#portfolio .uploadButton svg {
  margin-right: 17px;
  width: 1vw;
}
#portfolio .dropzoneIcon {
  position: relative;
  bottom: 250px;
  display: flex;
  justify-content: center;
  //left: 95px;
}
#portfolio .dropzoneIcon svg {
  width: 2vw;
  z-index: 0;
}
#portfolio .dz-message span {
  padding-top: 25px;
  font-family: Montserrat;
  font-size: 1vw;
  color: #8e97af;
  font-weight: 500;
}
#portfolio .dz-message {
  position: absolute;
  top: 25%;
  padding-left: 5%;
  padding-right: 5%;
}
#portfolio .dropzoneContainer {
  height: 250px;
}
#portfolio .collapsed .when-open,
.not-collapsed .when-closed {
  display: flex;
  align-items: center;
}
#portfolio .not-collapsed .when-open,
.collapsed .when-closed {
  display: none;
}
#portfolio .portfolioInfos {
  margin-right: 1%;
  width: 11%;
}
#portfolio .portfolioInfos_label {
  font-size: 15px;
  font-weight: 600;
}
#portfolio .portfolioInfos_value {
  font-size: 20px;
}
/*
  tr:nth-child(odd) {
    background-color: #c6ccdd;
  }
  
  tr:nth-child(even) {
    background-color: white;
  }
  
  th {
    background-color: white;
  }
  
  tr[th] {
    margin-bottom: 20px;
  }
  
  .detailTable tr:nth-child(odd) {
    background-color: #9099b0;
  }
  
  .detailTable tr:nth-child(even) {
    background-color: rgb(255, 255, 255);
  }
  
  .detailTable th {
    background-color: #c6ccdd;
  }
  
  .table-hover tbody tr:hover,
  .table-hover tbody tr:hover {
    background-color: #8f98ae;
  }
  
  .detailTable .table-hover tbody tr:hover,
  .detailTable .table-hover tbody tr:hover {
    background-color: yellow;
  }*/
</style>
